body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container{
  max-width: 960px;
  margin-right:auto;
  margin-left:auto;
  padding-right: 15px;
  padding-left: 15px;
}
.notes-list{
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
}
.note{
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
 white-space: pre-wrap;
  border-radius: 10px;
  padding: 1rem;
  min-height: 170px;
  display: flex;
  flex-direction: column;
justify-content: space-between;
}
.note-footer{
  display: flex;
  align-items: center;
  justify-content:space-between;
}
textarea{
  border:none;
  resize: none;
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
}
.note.new{
  background-color: aquamarine;
}
textarea:focus{
  outline: none;
}
.save{
  background-color: rgb(233, 233, 233);
  border:none;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  border:solid 4px #308d46;
}
.save:hover{
  background-color: #308d46;
  cursor: pointer;
}
.delete-icon{
  cursor: pointer;
}
.search{
  display: flex;
  align-items: center;
  background-color: rgb(233, 233, 233);
  border-radius: 10px;
  padding: 1.5em;
  margin-bottom: 10px;
  border:solid 4px #308d46;
}
.search input{
  border: none;
  background-color: rgb(233, 233, 233);
  
}
.search input:focus{
  outline: none;
}
.dark-mode{
  background-color: black;
  min-height: 100vh;
}
.dark-mode h1{
  color: white;
}